<!-- 更新签约信息弹窗 -->
<template>
  <div>
    <el-dialog
        width="30%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'开票申请':'开票申请'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100px">
        <el-form-item label="选择开票信息">
          <div style="display: flex;align-items: center;">
            <el-select
                clearable
                @change="change"
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option v-for="(item,index) in select" :label="item.company_name" :value="item.id"/>
            </el-select>
            <el-button style="margin-left: 20px;" @click="To">管理开票信息</el-button>
          </div>
        </el-form-item>
        <el-form-item label="开票金额">
          <div style="display: flex;align-items: center;">
            <el-input
                style="width: 60%!important;"
                clearable
                :maxlength="20"
                v-model="form.invoice_member"
                placeholder="请输入开票金额"/>
            <span style="margin-left: 20px;">可开票 {{money}}</span>
          </div>
        </el-form-item>

      </el-form>

      <div slot="footer" >
        <!--<el-button-->
        <!--    @click="updateVisible(false)">取消-->
        <!--</el-button>-->
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save('form')">申请开票
        </el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {billing_invoice, get_invoice_select} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    money: String,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        invoice_member:''
      },
      // 验证规则
      rules: {
        realname: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        serve_type: [
          { type: 'array', required: true, message: '请选择', trigger: 'change' }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      select:[],
      id:'',

    };
  },
  // watch: {
  //   data() {
  //     if (this.data) {
  //       console.log(this.data)
  //       this.form = Object.assign({}, this.data);
  //       this.isUpdate = true;
  //     } else {
  //       this.form = {};
  //       this.isUpdate = false;
  //     }
  //   }
  // },

  mounted() {
    console.log(this.data)

    this.getSelect();
  },

  methods: {
    // 获取下拉
    getSelect() {
      get_invoice_select().then(res => {
        this.select = res.data;
      });
    },

    change(value){
      console.log(value)
      this.id = value;
    },

    /* 保存编辑 */
    save() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //
      //   } else {
      //     return false;
      //   }
      // });
      if(this.id == ''){
        this.$message.error('请选择开票信息');
      }else if(this.form.invoice_member == ''){
        this.$message.error('请输入开票金额');
      }else {
        let data = {
          invoice_member: this.form.invoice_member
        }
        billing_invoice(this.id,data).then(res => {
          if (res.code == 200) {
            this.$message.success('申请开票成功');
            this.loading = true;
            this.updateVisible(false);
            this.$emit('done');
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    To(){
      this.$router.push('/operate/reconciliationDataSettings')
    },


  }
}
</script>

<style scoped lang="scss">

</style>
